.navbar {
  padding: 0.3rem;

  @include media-breakpoint-down(sm) {
    padding-left: 1rem;
  }

  .navbar-brand {
    @include media-breakpoint-down(sm) {
      span {
        display: none;
      }
    }
  }
}

@media (max-width: 576px) {
  .modal-dialog.modal-dialog-slideout {
      width: 80%
  }
}

.modal-dialog-slideout {
  min-height: 100%;
  margin: 0 auto 0 0;
  background: #fff;
}

.modal.fade .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(-100%, 0);
  transform: translate(-100%, 0);
}

.modal.fade.show .modal-dialog.modal-dialog-slideout {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  flex-flow: column;
}

.modal-dialog-slideout .modal-content {
  border: 0;
  box-shadow: none;
}
