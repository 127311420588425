.introduction {
  text-align: center;

  .container {
    display: grid;
    grid-gap: 5rem;
    grid-template-columns: repeat(3, auto);

    @include media-breakpoint-up(lg) {
      padding: 10rem;
    }

    @include media-breakpoint-down(md) {
      grid-gap: 1rem;
      grid-template-columns: repeat(2, auto);
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: auto;
    }
  }

  .header {
    grid-column: 1/-1;
    align-self: flex-end;
    font-size: 2rem;

    @include media-breakpoint-down(md) {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  .item {
    align-self: flex-start;

    > div {
      > span {
        background: gray;
        color: white;
        display: inline-block;
        font-weight: bold;
        margin-top: 0.5rem;
        margin-bottom: 1.5rem;
        padding: 0.25rem 1rem;

        @include media-breakpoint-down(md) {
          margin-top: 0;
          margin-bottom: 0.5rem;
        }
      }
    }

    > p {
      text-align: left;

      @include media-breakpoint-down(md) {
        text-align: center;
      }
    }
  }
}
