.home {
  background-color: var(--dark);

  h2 {
    // font-family: $secondaryFont;
    background: none;
    color: white;
    font-size: 3rem;
    margin: 0;
    padding: 0;
    text-align: left;
  }

  .slogan-hero {
    height: 91vh;
    background-image: url("/images/home-hero-16-9.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-start;

    @keyframes fadeInLeft {
      from {
        margin-left: -20px;
      }
      to {
        filter: opacity(100%);
        transform: translate(10px);
      }
    }

    p {
      margin: 1rem;
      filter: opacity(0%);
      animation: fadeInLeft 1.5s ease-in-out 1 forwards;
    }

    .slogan {
      font-family: "Krona One", sans-serif;
      font-size: 2.5rem;
      color: var(--dark);
      padding: 12rem 3rem;
      z-index: 9;

      p:nth-of-type(2) {
        animation-delay: 1s;
      }

      p:nth-of-type(3) {
        animation-delay: 1.75s;
      }
    }
  }

  @include media-breakpoint-down(md) {
    h2 {
      font-size: 1.25rem;
    }

    .slogan-hero {
      background-image: url(/images/home-hero-3-4.jpg);
      align-items: flex-end;

      #landingOverlay {
        background-color: var(--dark);
        opacity: 0.5;
        height: 100vh;
        width: 100%;
        position: absolute;
      }

      .slogan {
        font-size: 1.6rem;
        padding: 0;
        color: white;
        margin-bottom: 10rem;

        p:nth-of-type(n) {
          padding: 0;
        }
      }
    }
  }
}
