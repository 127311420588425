main:not(.custom-theme),
.modal-slim {
  > article {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  h1,
  h2,
  h3 {
    font-weight: 500;
  }

  h1 {
    font-size: 6rem;
  }

  h2 {
    background: $primary;
    color: white;
    font-size: 1.5rem;
    padding: 0.5rem 1rem;
  }

  h3 {
    color: var(--dark);
    font-size: 1.2rem;
  }

  article {
    > h2 {
      margin: 0;
    }
  }

  section {
    border: 1px solid #ddd;
    border-top: none;

    &.grid {
      border: none;
      padding: 1rem 0;
    }

    &:not(.grid) {
      background: white;
      padding: 1rem;

      &:last-of-type + hr,
      &:last-of-type + br {
        display: none;
      }
    }

    > .grid {
      padding: 1rem;
    }
  }
}
