.get-started {
  font-weight: 500;
  text-align: center;

  .container {
    display: grid;
    grid-gap: 1rem;
    grid-auto-columns: auto;
  }

  .header {
    p {
      font-size: 1.3rem;
      margin-top: 2rem;
      margin-bottom: 0;
    }
  }

  .subgrid {
    display: grid;
    justify-content: center;
    grid-gap: 4rem;
    grid-template-columns: repeat(3, auto);
    max-width: 100%;
  }

  figure {
    margin: 0;
  }

  figcaption {
    margin-top: 1rem;
  }

  .guide {
    background: #ffc000;
    margin-top: 1rem;

    p {
      margin: 0.5rem;
    }

    button {
      margin-bottom: 0.5rem;
      padding: 0 2rem;
    }
  }

  @include media-breakpoint-down(sm) {
    h2 {
      font-size: 1.4rem;
      font-weight: bold;
    }

    br {
      display: none;
    }

    .subgrid {
      grid-gap: 2rem;
      grid-template-columns: auto;
    }

    figure img {
      width: 100px;
    }

    figcaption {
      font-size: 1.2rem;
      font-weight: bold;
    }

    .guide {
      p {
        margin: 0.5rem 1rem;
      }

      button {
        margin-bottom: 0;
        padding: 0.3rem 2rem;
        width: 100%;
      }
    }
  }
}
