.annotations {
  canvas {
    touch-action: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &.ghost {
    &,
    * {
      pointer-events: none;
    }

    .annotations-menu {
      * {
        pointer-events: auto;
      }
    }
  }
}

.annotations-menu {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: auto;

  .trigger {
    background: #ababab;
    border: 4px solid white;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    width: 3rem;

    > * {
      padding: 4px;
      height: 70%;
      width: 70%;
      object-fit: scale-down;
    }
  }

  .dropdown-container {
    padding: 1rem;
    min-width: 15rem;
    max-width: calc(100vw - 2rem);

    > div {
      margin-bottom: 1rem;

      > div:first-of-type {
        margin-bottom: 0.25rem;
      }
    }
  }

  .mode-grid {
    display: flex;

    > * {
      background: #ababab;
      border: 2px solid transparent;
      margin-right: 0.5rem;
      padding: 0;
      height: 2rem;
      width: 2rem;

      &.active,
      &:hover {
        border-color: black;
      }

      > * {
        padding: 2px;
        height: 70%;
        width: 70%;
        object-fit: scale-down;
      }
    }
  }

  .color-square {
    background: var(--color);
    border: 1px solid black;
    display: inline-block;
    height: 0.8rem;
    width: 0.8rem;
    margin-right: 0.33rem;
  }
}
