.carousel {
  color: white;
  position: relative;

  .dots {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
    display: flex;
    margin-right: -0.5rem;
    padding: 0.5rem;

    > * {
      cursor: pointer;
      margin-right: 0.5rem;
    }
  }
}
