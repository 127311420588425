$enable-gradients: true;
$enable-rounded: false;
$enable-shadows: true;

// $primary: #557A95;
$primary: #1894d5;
$primary-dark: #185984;
$secondary: #f3f6f8;
// $dark: black;
$dark-gray: #5d5c61;
$light-blue: #b3c5d9;
$light-gray: #f5f5f5;
$add: green;
$edit: blue;
$remove: red;
$success: $add;
$tinted: #f3f6f8;

//Custom classes and style variables
$tertiary: #32b2d3;
$shadow: #002028;
//end custom classes and style variables

$theme-colors: (
  "primary-dark": $primary-dark,
  secondary: $secondary,
  // dark: $dark,
  "dark-gray": $dark-gray,
  "light-blue": $light-blue,
  "light-gray": $light-gray,
  add: $add,
  edit: $edit,
  remove: $remove,
  success: $success,
);

$action-width: 5rem;

@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~bootstrap/scss/bootstrap";
@import "~react-toastify/dist/ReactToastify.css";

@import "./Annotations.scss";
@import "./Carousel.scss";
@import "./ExpansionSection.scss";
@import "./Home.scss";
@import "./landing/Landing.scss";
@import "./NavBar.scss";
@import "./Sessions.scss";
@import "./Notifications.scss";
@import "./Theme.scss";
@import "./Video.scss";

html,
body {
  background-color: #e9ebee;
  min-height: 100vh;
  font-family: "Montserrat", sans-serif;
  font-size: 13pt;
}

* {
  min-height: 0;
  min-width: 0;
}

a,
a:hover {
  color: inherit;
  text-decoration: inherit;
}

img {
  max-width: 100%;
}

.dropdown-item {
  color: var(--dark);
  cursor: pointer;

  i {
    color: var(--primary);
    margin-right: 0.5rem;
    text-align: center;
    width: 1.2rem;
  }
}

.modal-slim-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1500;
}

.modal-slim {
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;
  max-width: 100%;
  z-index: 1501;
  box-shadow: 3px 3px 5px 6px rgba(0, 0, 0, 0.4);
}

.attachments {
  > div {
    border: 1px solid #6c757d70;
    padding: 0;

    > a {
      align-items: center;
      display: flex;

      .icon {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 1.5rem;
        width: 1.5rem;
      }

      .file {
        padding: 0 0.75rem 0 0.25rem;
      }
    }

    > .remove {
      align-items: center;
      background: #dc354570;
      display: flex;
      justify-content: center;
      height: 1.5rem;
      margin: 0;
      padding: 0;
      width: 1.5rem;
    }
  }
}

.border {
  border: 1px solid #ddd;
}

.border-without-left {
  border: 1px solid #ddd;
  border-left: none;
}

.border-without-top {
  border: 1px solid #ddd;
  border-top: none;
}

.circle {
  border-radius: 50%;
}

.camera-blink {
  -webkit-animation-name: camera-blinker;
  -webkit-animation-duration: 200ms;
  -webkit-animation-timing-function: linear;

  -moz-animation-name: camera-blinker;
  -moz-animation-duration: 200ms;
  -moz-animation-timing-function: linear;

  animation-name: camera-blinker;
  animation-duration: 200ms;
  animation-timing-function: linear;
}

@-moz-keyframes camera-blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes camera-blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes camera-blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.cropImage {
  width: 100%;
  height: 100%;
  position: absolute !important;
  top: 0;
  background-color: black;
  > div,
  .inner-crop-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.recordIcon {
  > button {
    color: black;

    background: rgba(227, 73, 28, 0.8);
    outline: none;
    border: none;

    &:hover {
      background: #fb6d42;
    }
  }

  &.start {
    border: 8px solid rgba(255, 255, 255, 0.4);

    > button {
      border-radius: 50%;
      width: 64px;
      height: 64px;
    }
  }

  &.stop {
    background: rgba(255, 255, 255, 0.4);

    > button {
      border-radius: 4px;
      width: 40px;
      height: 40px;
      margin: 20px;
    }
  }
}

.capture-actions {
  bottom: 0;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  left: 44%;
  color: white;
  &.hide {
    display: none;
  }
  > * {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    border: 4px solid transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active,
    &:hover {
      background: #ababab;
      border-color: white;
    }
  }
}

.slowIcon {
  > * {
    padding: 4px;
    height: 70%;
    width: 70%;
    object-fit: scale-down;
  }
}

.grid {
  display: grid;
  grid-gap: 1rem 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(17rem, 1fr));
}

.info-items,
.info-wrap {
  align-items: center;
  color: gray;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.8rem;

  i {
    font-size: 1rem;
  }

  > * {
    align-items: center;
    display: flex;
    margin: 0 0.75rem 0.75rem 0;
    white-space: nowrap;

    > * {
      margin: 0 0.25rem 0 0;
    }
  }
}

.info-wrap {
  justify-content: space-between;
}

button.action {
  min-width: $action-width;
}

.btn-outline {
  background: white;
}

.cursor-pointer {
  cursor: pointer;
}

.eq-width {
  &.fas,
  &.far {
    width: 1rem;
  }
}

.hover-only-trigger {
  .hover-only {
    visibility: hidden;
  }

  &:hover .hover-only {
    visibility: visible;
  }
}

.material {
  .form-control,
  .form-control:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    box-shadow: none;
    border-radius: 0;
  }

  .form-control:invalid:not(:placeholder-shown) {
    border-bottom-color: red;
  }

  .autosize {
    border: none;
    box-shadow: none;
  }
}

.sliver {
  color: white;
  font-weight: bold;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  backface-visibility: hidden;
  text-align: center;
}

.autosize {
  overflow-y: hidden;
  resize: none;
}

.comment {
  .stamp {
    font-size: 0.8rem;
    display: none;
  }

  &:hover .stamp {
    display: block;
  }
}

.btn:focus {
  box-shadow: initial;
}

.card {
  background: white;
}

.card-body {
  padding: 0.75rem;
}

.embed-responsive-item {
  background: black;
  object-fit: contain;
}

.btn-circle {
  border-radius: 50%;
}

.flex-content-fill > * {
  flex-grow: 1;
  flex-shrink: 1;
}

.form-group > label {
  width: 100%;
}

.input-group-text .fas {
  width: 1rem;
}

.list-group > li > a {
  color: inherit;
  text-decoration: none;
}

.list-group-item {
  .minor {
    color: gray;
    font-size: 0.75rem;
  }
}

@media only screen and (min-width: 1400px) {
  .container {
    max-width: 1340px;
  }
}

@media only screen and (min-width: 1900px) {
  .container {
    max-width: 1600px;
  }
}

// CSS Tricks: https://css-tricks.com/aspect-ratio-boxes/
[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}
[style*="--aspect-ratio"] > img {
  height: auto;
}
@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }
  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}
