.expansion-section {
  color: white;
  display: flex;
  min-height: 90vh;
  align-items: center;

  .connection {
    background-image: url("/images/connection.jpg");
  }

  .recovery {
    background-image: url("/images/running.jpg");
    flex-direction: row-reverse;
    background-color: #0e5062;
  }

  .growth {
    background-image: url("/images/growth.jpg");
  }

  .sectionText {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    padding: 10vw;
    font-size: 2rem;
    filter: opacity(0%);

    h2 {
      margin-bottom: 1.5rem;
    }
  }

  .sectionImage {
    margin: 0;
    background-size: cover;
    background-position: center;
    flex: 1 1 0;
    align-self: stretch;
  }

  .imageOverlay {
    background-color: var(--dark);
    filter: opacity(40%);
    height: inherit;
    width: inherit;
  }

  @keyframes fadeInTop {
    from {
      margin-top: -20px;
    }
    to {
      filter: opacity(100%);
    }
  }

  .inViewText {
    animation: 3s fadeInTop 0.75s forwards;
  }

  @include media-breakpoint-down(md) {
    position: relative;
    margin-top: 4rem;
    box-sizing: border-box;

    .sectionText {
      position: relative;
      z-index: 99;
      font-size: 1.3rem;

      h2 {
        font-size: 2rem;
      }
    }

    .sectionImage {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  @include media-breakpoint-up(lg) {
    &:nth-of-type(2n - 1) {
      flex-direction: row-reverse;
      background-color: var(--primary-dark);
    }

    .sectionText {
      max-width: 55%;
    }

    .sectionImage {
      filter: opacity(0%);
    }

    @keyframes fadeIn {
      to {
        filter: opacity(100%);
      }
    }

    .inViewImage {
      animation: 3s fadeIn 0.5s forwards;
    }
  }
}
