.hero {
  background: black;
  color: white;
  position: relative;

  &::after {
    background: url("/images/landing_hero_wide.jpg") no-repeat center top;
    background-size: cover;
    content: "";
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 1;
  }

  * {
    z-index: 2;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr 1fr;
  }

  .body,
  .footer {
    width: 50%;
  }

  .header {
    font-weight: 500;
    justify-self: left;
  }

  .body {
    align-self: flex-end;
    text-align: center;

    h2 {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 0.5rem;
    }
  }

  .footer {
    align-self: flex-start;
    text-align: center;
    margin-top: 1rem;

    button {
      align-self: center;
    }
  }

  @include media-breakpoint-down(sm) {
    &::after {
      background-image: url("/images/landing_hero_tall.jpg");
    }

    .body,
    .footer {
      width: 100%;
    }

    .footer {
      align-self: flex-end;
      margin-bottom: 4rem;
    }
  }
}
