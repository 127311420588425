.sessions-grid {
  display: grid;
  grid:
    "plan . patient" auto
    "session . patient" 1fr
    / auto 1fr auto;
  grid-column-gap: 1rem;

  .plan {
    grid-area: plan;
    color: gray;
    font-size: 0.75rem;
  }

  .session {
    grid-area: session;
  }

  .patient {
    grid-area: patient;
    display: flex;
    align-items: center;
  }
}
