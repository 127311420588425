.video-recorder-wrapper {
  // position: relative;
  // display: flex;
  // flex-direction: column;
  // -webkit-box-align: center;
  // align-items: center;
  // -webkit-box-pack: center;
  // justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-height: 300px;
  background-color: rgb(0, 0, 0);
  color: white;
  box-sizing: border-box;

  > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .switch-camera-view {
    width: 80px;
    height: 80px;
    bottom: 4px;
    right: 4px;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    padding-left: 8px;
    padding-top: 10px;
    position: absolute;
    cursor: pointer;
  }

  .video-timer-wrapper {
    position: absolute;
    top: 50px;
    right: 50px;
    font-family: Menlo, monospace;
    font-size: 28px;
    text-shadow: rgba(0, 0, 0, 0.5) 1px 2px;
    .video-timer {
      width: 16px;
      height: 16px;
      background: rgb(229, 82, 38);
      border-radius: 50%;
      float: left;
      margin: 2px 8px 2px 0px;
    }
  }

  .record-video-action-wrapper {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 20px;
    margin-bottom: 80px;

    .reset-recorded-video {
      background: white;
      color: black;
      border-radius: 4px;
      height: 40px;
      padding: 0px 18px;
      border: none;
      margin: -8px;
      font-size: 14px;
      font-weight: bold;
      outline: none;
      cursor: pointer;
      &:hover {
        background: rgb(238, 238, 238);
      }
    }

    .record-video-action {
      display: flex;
      flex-direction: column;
      -webkit-box-align: center;
      align-items: center;

      .record-video-action-button-wrapper {
        border: 8px solid rgba(255, 255, 255, 0.4);
        height: 80px;
        width: 80px;
        border-radius: 50%;

        > button {
          color: black;
          background: rgba(227, 73, 28, 0.8);
          outline: none;
          border: none;
          cursor: pointer;
          &:hover {
            background: rgb(251, 109, 66);
          }
          &.stop-recording {
            border-radius: 4px;
            width: 40px;
            height: 40px;
            margin: 12px;
          }
          &.start-recording {
            border-radius: 50%;
            width: 64px;
            height: 64px;
            z-index: 5;
          }
        }
      }

      .record-video-text {
        font-family: Arial;
        font-size: 14px;
        color: rgb(255, 255, 255);
        letter-spacing: 1.75px;
        display: flex;
        margin-bottom: 20px;

        span {
          color: rgb(220, 101, 71);
          font-weight: 700;
        }
      }
    }
  }

  .video-camera-recorder {
      position: absolute;
      top:0;
      left: 0;
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
    }

  .camera-view-wrapper {
    height: 100%;
    width: 100%;

    .capture-video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-height: 100%;
      min-width: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .video-input-wrapper {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    padding-top: 20px;
    margin-bottom: 80px;

    .camera-view-wrapper {
      height: 100%;
      width: 100%;
      background-color: orange;
      display:block;
      right: 0px;
    }

    .video-input-button {
      background: white;
      color: black;
      border-radius: 4px;
      height: 40px;
      padding: 0px 18px;
      border: none;
      margin: -8px;
      font-size: 14px;
      font-weight: bold;
      outline: none;
      cursor: pointer;
    }
  }
}
