.value {
  background: var(--primary);
  color: white;

  .container {
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: repeat(2, auto);
  }

  .screenshot {
    grid-row: span 2;
  }

  .header {
    font-size: 2rem;
    font-weight: 500;
    text-align: center;
  }

  .subgrid {
    display: grid;
    grid-gap: 4rem;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .icon {
    text-align: center;
  }

  .title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  @include media-breakpoint-down(md) {
    .container {
      grid-gap: 2rem;
      grid-template-columns: auto;
    }

    .screenshot {
      text-align: center;
    }

    .subgrid {
      grid-gap: 2rem;
      grid-template-columns: repeat(2, auto);
      text-align: center;
    }
  }

  @include media-breakpoint-down(sm) {
    br {
      display: none;
    }

    .header {
      grid-row: 1;
    }

    .subgrid {
      grid-template-columns: 1fr;
    }
  }
}
