.nt-note {
  padding: 1.2rem 0.8rem;
  border-top: 1px solid #f5f5f5;
  display: flex;
  justify-content: space-between;
  &:first-child {
    border-top: none;
  }
}

#notifications .modal-body {
  padding: 0;
}
