.landing {
  font-size: 1rem;

  h2 {
    font-size: 2rem;
  }

  > article {
    min-height: calc(100vh - 5rem);
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .container {
    min-height: inherit;
    align-content: center;
    justify-content: center;
  }

  @include media-breakpoint-up(md) {
    b {
      font-weight: inherit;
    }
  }

  @import "./Hero.scss";
  @import "./Introduction.scss";
  @import "./Value.scss";
  @import "./GetStarted.scss";
}
